<template>
  <div class="the-navbar__user-meta flex items-start" v-if="activeUserInfo.name">
<div v-on:mouseleave="buttonGrow = false">
    <!-- <div style="height: 40px;"  @click="buttonGrow = !buttonGrow"   class="con-img ml-2 flex bg-h-primary items-center  rounded-full py-3 justify-around" v-b-toggle.collapse-2>
      <feather-icon  icon="UserIcon" class=" text-white cursor-pointer" svgClasses="w-6 h-6" />
    </div> -->
    <!-- <span class="text-success"  >سلام {{ activeUserInfo.name }} عزیز</span> -->

    <div>
  <b-dropdown :text="activeUserInfo.name+' عزیز'" block variant="primary" class="m-2" right>
    <b-dropdown-item href="#">
      <span
        class="flex py-2 cursor-pointer user user-drop text-primary"
        @click="$router.push('/pages/user-settings')">
        <span class="">
          <feather-icon icon="SettingsIcon" svgClasses="w-4 h-4" /> تنظیمات کاربری
        </span>
      </span>
    </b-dropdown-item>
    <b-dropdown-item href="#">
      <span
        class="flex py-2 cursor-pointer user user-drop text-primary"
        @click="$router.push('/apps/addKey')">
        <span class="">
          <feather-icon icon="LockIcon" svgClasses="w-4 h-4" /> افزودن کلید SSH
        </span>
      </span>

    </b-dropdown-item>

    <b-dropdown-item href="#">
     <span
        class="flex py-2 cursor-pointer user user-drop text-primary"
        @click="logout">
        <span class="">
          <feather-icon icon="LogOutIcon" svgClasses="w-4 h-4" /> خروج
        </span>
      </span>
    </b-dropdown-item>
  </b-dropdown>


</div>

</div>
</div>
</template>

<script>
import useJwt from '@/http/authConfig'

export default {
  data () {
    return {
      buttonGrow: false
    }
  },
  computed: {
    activeUserInfo () {
      return this.$store.state.AppActiveUser
    },
    windowWidth () {
      return this.$store.state.windowWidth
    }
  },
  methods: {
    logout () {
      // this.$router.push('/pages/login').catch(() => {})
      this.$http('user/logout').then((res) => {
        localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
        localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
        localStorage.removeItem('userInfo')
        localStorage.removeItem('dis')

        this.$vs.notify({
          title: 'خروج',
          text: res.data.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'primary'
        })

        // locationbar.removeItem('isauth')

        this.$store.commit('auth/USER_LOGIN_CONTROL', {value :false})
        // go to login

        this.$router.push('/')

      }).catch((err) => {
        window.location.href = '/'
        // this.$router.push('/pages/login').catch(() => {})
        localStorage.clear()
        this.$vs.notify({
          title: 'Error',
          text: err.response.data.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      })

    }
  },
  beforeDestroy () {
    // location.reload()
  }
}
</script>

