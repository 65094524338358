 <template >
  <div class="">
      <vs-dropdown  color="white">
        <div class="flex">
          <vs-button class="rounded-pill" size="small" type="border" :color="activeUser.balance >= 10000 ? 'success' : 'warning'" icon="expand_more">{{activeUser.balance | money }} تومان</vs-button>
          <div v-if="activeUser.post_paid" class="mx-4 mt-1 bg-grey-light px-4 py-1 rounded-pill" >
            <b-icon font-scale="1.2" icon="check" variant="primary" ></b-icon>
            <span>پس پرداخت</span>
          </div>

        </div>
        <vs-dropdown-menu >
          <vs-dropdown-item>
            <vs-button size="small" @click="activePrompt = true" class="my-1" color="#333"  icon="add" type="line"> افزایش اعتبار </vs-button>
          </vs-dropdown-item>
        </vs-dropdown-menu>
      </vs-dropdown>
     <vs-prompt
     class="w-full"
      color="primary"
      title="خرید"
      @accept="acceptAlert"
      accept-text="پرداخت"
      cancel-text="انصراف"
      :active.sync="activePrompt"
     >
       <div class="con-exemple-prompt">
          <vs-input-number v-model="amountCharge"  step="10000"/>
          <p class="text-center">تومان</p>
           <vs-alert :active="true" color="primary" icon="new_releases" >
              <span> مبلغ قابل پرداخت با احتساب 10 درصد مالیات ارزش افرزوده <b class="text-success">{{realPrice | money}}</b> تومان</span>
            </vs-alert>
       </div>
     </vs-prompt>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
  components: {
    VuePerfectScrollbar
  },
  data () {
    return {
      amountCharge : 80000,
      activePrompt : false,
      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60
      }
    }
  },
  computed: {
    // CART DROPDOWN
    realPrice () {
      return (+this.amountCharge + (0.10 * this.amountCharge))
    },
    scrollbarTag () {
      return this.$store.getters.scrollbarTag
    },
    activeUser () {
      return this.$store.state.AppActiveUser
    }
  },
  methods: {

    acceptAlert () {

      // const authtoken = localStorage.getItem('accessToken')

      this.$http.post('https://api.haio.ir/v1/invoice/charge/add', {
        amount: this.amountCharge, payment_side:'2', redirect_url: 'https://console.haio.ir/pages/payment'
      })
        .then(async (res) => {
          window.location = (res.data.params.payment_url)
          // console.log("Set follow txt record at your dns server:")
          // console.log(res.data.params)
        })
        .catch(error => {
          this.$vs.notify({
            color:'danger',
            title:' شارژ حساب',
            text:error.response.data.message
          })

        })
    }
  }

}


</script>
