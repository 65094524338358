
<template>
  <div  class="relative">
    <!-- <p>{{windowWidth}}</p> -->
    <div class="vx-navbar-wrapper shadow-md " :class="classObj">
      <vs-navbar class="vx-navbar navbar-custom navbar-skelton" :color="navbarColorLocal" :class="textColor">
          <feather-icon v-if="windowWidth < 1201" class="sm:inline-flex xl:hidden cursor-pointer p-2 " icon="MenuIcon" @click.stop="showSidebar" />
        <vs-row>

        <!-- SM - OPEN SIDEBAR BUTTON -->

        <!-- <bookmarks :navbarColor="navbarColor" v-if="windowWidth >= 992" /> -->

        <!-- <vs-spacer /> -->

        <!-- <i18n /> -->
        
          <vs-col v-if="windowWidth > 800" class="flex items-center" vs-w="1">
            <events-part />
          </vs-col>
        <!-- <notification-drop-down /> -->
        <vs-col class="flex justify-end items-center" vs-w="11" :vs-sm="windowWidth > 800 ?'11' : '12'" vs-xs="12">
          <setting-page />
          <profile-drop-down />
        </vs-col>
        </vs-row>
      </vs-navbar>
    </div>
  </div>
</template>


<script>
import Bookmarks            from './components/Bookmarks.vue'
import I18n                 from './components/I18n.vue'
import SearchBar            from './components/SearchBar.vue'
import EventsPart         from './components/EventsPart.vue'
import NotificationDropDown from './components/NotificationDropDown.vue'
import ProfileDropDown      from './components/ProfileDropDown.vue'
import SettingPage       from './components/SettingPage.vue'


export default {
  name: 'the-navbar-vertical',
  props: {
    navbarColor: {
      type: String,
      default: '#fff'
    }
  },
  components: {
    Bookmarks,
    I18n,
    SearchBar,
    EventsPart,
    NotificationDropDown,
    ProfileDropDown,
    SettingPage
  },
  computed: {
    navbarColorLocal () {
      return this.$store.state.theme === 'dark' && this.navbarColor === '#3f3f3f' ? '#10163a' : this.navbarColor
    },
    verticalNavMenuWidth () {
      return this.$store.state.verticalNavMenuWidth
    },
    textColor () {
      return {'text-white': (this.navbarColor !== '#10163a' && this.$store.state.theme === 'dark') || (this.navbarColor !== '#fff' && this.$store.state.theme !== 'dark')}
    },
    windowWidth () {
      return this.$store.state.windowWidth
    },

    // NAVBAR STYLE
    classObj () {
      if      (this.verticalNavMenuWidth === 'default') return 'navbar-default'
      else if (this.verticalNavMenuWidth === 'reduced') return 'navbar-reduced'
      else if (this.verticalNavMenuWidth)               return 'navbar-full'
    }
  },
  methods: {
    showSidebar () {
      this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', true)
    }
  }
}
</script>

