<template>
<router-link to="/new/server/">
    <vs-button  style="width: 150px;" class="rounded-pill bg-h-secondary" type="filled">ساخت سرور </vs-button>
</router-link>
</template>
<script>
export default {
  data () {
    return {
      buttonGrow: false
    }
  },
  computed: {
    windowWidth () {
      return this.$store.state.windowWidth
    }
  },
  watch: {
    windowWidth () {
      if (this.windowWidth < 800) {
        this.buttonGrow = false
      }
    }
  }
}
</script>

<style scoped>
.user {
  background-color: #3f2553;
  color: rgb(255, 255, 255);
  border-radius: 15px;
  padding: 0.6rem 1.4rem;
}

.user-drop:hover {
  background-color: #ffffff;
  color: black;
  border-radius: 5px;
  /* padding: 0 10px; */
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.typing-demo {
  width: 32ch;
  animation: typing 2s steps(22), blink 0.5s step-end infinite alternate;
  white-space: nowrap;
  overflow: hidden;
  border-right: 3px solid;
  font-family: monospace;
  /* font-size: 2em; */
}

@keyframes typing {
  from {
    width: 0;
  }
}

@keyframes blink {
  50% {
    border-color: transparent;
  }
}
</style>
