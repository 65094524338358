<template>
 <div>
   <!-- NOTIFICATIONS -->
<div>
  <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">
    <feather-icon  :badge="contOfBagde" icon="BellIcon" class="cursor-pointer selected-icon py-2 rounded-pill mt-1 mr-0"  />
    <vs-dropdown-menu v-if="showDropdownNotification" class="notification-dropdown dropdown-custom vx-navbar-dropdown border rounded-lg border-h-primary-light">
      <div class="notification-top text-center p-5 primary-color text-white">
        <h3 class="opacity-75 text-white" >اعلانات </h3>
        <!--        <p class="text-white"> جدید : {{ unreadNotifications.length }}</p>-->
      </div>
      <component :is="scrollbarTag"   ef="mainSidebarPs" class="scroll-area--nofications-dropdown p-0 mb-10" :settings="settings" :key="$vs.rtl">
        <ul v-if="false" class="bordered-items">
          <li v-for="ntf in unreadNotifications" :key="ntf.index" class="flex justify-between px-4 py-4 notification cursor-pointer">
            <div class="flex items-start">
              <feather-icon id="tooltip-target-notife" icon="BellIcon" :svgClasses="[`text-${ntf.category}`, 'stroke-current mr-1 h-6 w-6']"></feather-icon>

            </div>
            <small class="mt-1 whitespace-no-wrap">{{ elapsedTime(ntf.time) }}</small>
          </li>
        </ul>

        <!--         <vs-list v-if="true">-->
        <!--           <div class="cursor-pointer">-->
        <!--              <div @click="showNotification(unreadEssentional.id, 1)">-->
        <!--                <vs-list-item>-->
        <!--                  <template slot="avatar">-->
        <!--                    <vs-avatar />-->
        <!--                    {{ unreadEssentional.title }}-->
        <!--                  </template>-->
        <!--                </vs-list-item>-->
        <!--              </div>-->
        <!--           </div>-->
        <!--         </vs-list>-->
        <!--         v-if="unreadNotifications.length > 0-->
        <vs-list v-if="true">
          <div v-for="(item, index) in unreadNotifications" :key="index" @click="showNotification(item.id)" class="cursor-pointer text-white">
            <vs-list-item class="text-summery-flex flex text-white" :title="item.title" >
              <template slot="avatar">
                <vs-avatar color="success" icon="notifications"/>
              </template>
              <!-- <vs-chip color="warning">{{item.status}}</vs-chip> -->
            </vs-list-item>
          </div>
        </vs-list>
        <div  class="w-full flex justify-center items-center mt-5 text-white" v-else>
          اعلانی جدید برای نمایش وجود ندارد !
        </div>
      </component>
    </vs-dropdown-menu>
  </vs-dropdown>

</div>

 </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
  components: {
    VuePerfectScrollbar
  },
  data () {
    return {
      contOfBagde: 0,
      unreadNotifications: [],
      unreadEssentional: [],
      notificationList: null,
      showDropdownNotification: true,
      testdata: '',
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: .60
      }
    }
  },
  computed: {
    scrollbarTag () { return this.$store.getters.scrollbarTag }
  },
  methods: {
    getUserNotificationZigi () {
      this.$http.get('notificationlistfordropdown').then(res => {
        this.unreadNotifications = res.data.params
        this.notificationList = res.data.params
      }).catch(err => {
        console.log(err.response)
      })
    },
    showNotification (id, isEssentional = 0) {
      this.showDropdownNotification = false
      setTimeout(() => {
        this.showDropdownNotification = true
      }, 100)
      if (isEssentional === 0) {
        this.$router.push({path: `/notification/show/${id}`})
      }
      this.$router.push({path: `/notification/show/${id}`, query: {type: isEssentional}})

    },
    getCountbadge () {
      this.$http.get('/count/notifications').then((res) => {
        this.contOfBagde = res.data.params
      }).catch((err) => {
        console.log('errr', err)
      })
    },
    // essentionalMessage () {
    //   this.$http.get('essentionalmessagefornotificationdropdown').then(res => {
    //     this.unreadEssentional = res.data.params
    //   }).catch(() => {
    //     // console.log(err)
    //   })
    // },
    elapsedTime (startTime) {
      const x        = new Date(startTime)
      const now      = new Date()
      let timeDiff = now - x
      timeDiff    /= 1000

      const seconds = Math.round(timeDiff)
      timeDiff    = Math.floor(timeDiff / 60)

      const minutes = Math.round(timeDiff % 60)
      timeDiff    = Math.floor(timeDiff / 60)

      const hours   = Math.round(timeDiff % 24)
      timeDiff    = Math.floor(timeDiff / 24)

      const days    = Math.round(timeDiff % 365)
      timeDiff    = Math.floor(timeDiff / 365)

      const years   = timeDiff

      if (years > 0) {
        return `${years + (years > 1 ? ' Years ' : ' Year ')}ago`
      } else if (days > 0) {
        return `${days + (days > 1 ? ' Days ' : ' Day ')}ago`
      } else if (hours > 0) {
        return `${hours + (hours > 1 ? ' Hrs ' : ' Hour ')}ago`
      } else if (minutes > 0) {
        return `${minutes + (minutes > 1 ? ' Mins ' : ' Min ')}ago`
      } else if (seconds > 0) {
        return seconds + (seconds > 1 ? ' sec ago' : 'just now')
      }

      return ''
    },
    // Method for creating dummy notification time
    randomDate ({ hr, min, sec }) {
      const date = new Date()

      if (hr) date.setHours(date.getHours() - hr)
      if (min) date.setMinutes(date.getMinutes() - min)
      if (sec) date.setSeconds(date.getSeconds() - sec)

      return date
    }

  },
  beforeMount () {
    this.getUserNotificationZigi()
  },
  created () {
    this.getUserNotificationZigi()
    this.getCountbadge()
  },
  mounted () {
    this.getCountbadge()
    setInterval(() => {
      this.getUserNotificationZigi()
      this.getCountbadge()
    }, 300000)
  }
}

</script>
<style>
.selected-icon {
  color: #fff;
}
.parent-area:hover .selected-icon {
  transition: .5s ease;
  color: white !important;
  background-color: #3f2553 !important;
}
</style>
