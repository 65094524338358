
export default [

  {
    url: '/dashboard',
    name: 'Dashboard',
    tag: '',
    tagColor: 'warning',
    icon: 'HomeIcon',
    i18n: 'Dashboard'

  },

  {
    header: 'CloudService',
    icon: 'LayersIcon',
    i18n: 'CloudService',
    items: [
      {
        url: '/new/server/',
        name: 'createServer',
        slug: 'createServer',
        icon: 'CloudRainIcon',
        i18n:  'createServer'
      },
      {
        url: '/apps/cloudlist',
        name: 'cloudlist',
        slug: 'cloudlist',
        icon: 'CloudIcon',
        i18n:  'CloudList'
      },
      {

        url: '/apps/addKey',
        name: 'Key',
        slug: 'Key',
        icon: 'KeyIcon',
        i18n:  'Key'
      }
    ]
  

  },

  // {
  //   header: 'Hosting',
  //   icon: 'LayersIcon',
  //   i18n: 'Hosting',
  //   items:[
  //     {

  //       url: '/apps/create/host',
  //       name: 'host',
  //       slug: 'host',
  //       icon: 'StarIcon',
  //       i18n:  'CreateHost'
  //     },
  //     {

  //       url: '/apps/list/host',
  //       name: 'host',
  //       slug: 'host',
  //       icon: 'StarIcon',
  //       i18n:  'HostList'
  //     }
  //   ]

  // },

  // End Service

  // Services Part
  // {
  //   header: 'Services',
  //   icon: 'FeatherIcon',
  //   i18n: 'Services',
  //   items:[

  //     {

  //       url: '/apps/services',
  //       name: 'service',
  //       slug: 'serviceList',
  //       icon: 'FeatherIcon',
  //       i18n:  'AddService'
  //     },
  //     {

  //       url: '/apps/service/list',
  //       name: 'service',
  //       slug: 'serviceList',
  //       icon: 'FeatherIcon',
  //       i18n:  'ServiceList'
  //     }
  //   ]

  // },
  // {
  //   header: 'Monitoring',
  //   icon: 'LayersIcon',
  //   i18n: 'Monitoring',
  //   items : [
  //
  //     // {
  //     //
  //     //   url: '/apps/monitoring',
  //     //   name: 'monitoring',
  //     //   slug: 'monitoring',
  //     //   icon: 'MonitorIcon',yarn
  //     //   i18n:  'AddDomain'
  //     // },
  //     {
  //
  //       url: '/apps/monitoring/ssl',
  //       name: 'monitoring',
  //       slug: 'monitoring',
  //       icon: 'LockIcon',
  //       i18n:  'SslReport'
  //     }
  //
  //
  //   ]
  // },
  // Ticketing Part
  {
    header: 'Support',
    icon: 'LayersIcon',
    i18n: 'Support',
    items:[
      {

        url: '/apps/ticket',
        name: 'ticket',
        slug: 'ticket',
        icon: 'StarIcon',
        i18n:  'Ticket'
      }
    ]

  },

  {
    header: 'InvoiceList',
    icon: 'LayersIcon',
    i18n: 'InvoiceList',
    items:[
      {
        url: '/apps/paymentManage',
        name: 'page-payment',
        slug: 'PaymentManage',
        icon: 'DollarSignIcon',
        i18n:  'PaymentManage'
      }
      // {
      //
      //   url: '/apps/payment',
      //   name: 'page-payment',
      //   slug: 'payment',
      //   icon: 'DollarSignIcon',
      //   i18n:  'Payment'
      // },
      //
      // {
      //
      //   url: '/apps/transaction',
      //   name: 'page-transicitoin',
      //   slug: 'transiction',
      //   icon: 'DollarSignIcon',
      //   i18n:  'Transaction'
      // },
      // {
      //
      //   url: '/apps/manual',
      //   name: 'page-manual',
      //   slug: 'manual',
      //   icon: 'DollarSignIcon',
      //   i18n:  'Manual'
      // }
    ]
  }

]
