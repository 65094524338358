<!-- =========================================================================================
  File Name: TheNavbar.vue
  Description: Navbar component
  Component Name: TheNavbar
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
<div class="relative">
  <nav class="navbar navbar-light pt-6 flex">

      <router-link tag="div" to="/mainpage" class="vx-logo cursor-pointer flex items-center w-1/6 lg:w-2/12 md:w-1/4 ">
        <Logo class="w-8 mr-0 md:mr-4 fill-current text-primary" />
        <div class="d-none text-white d-md-block">
          <span class="d-block">زیرساخت ابری هایو</span>
          <span class="font-light text-sm">ویـرا ابـر، آینده جـهـان</span>
        </div>
      </router-link>
      <ul class="navbar-nav mr-auto w-9/12 lg:w-1/2 md:w-7/12">
      <li class="nav-item flex justify-between">
        <setting-page />

      </li>

      </ul>
      <!-- add language form li18 folder -->

      <!-- <i18n />   -->

      <!-- <search-bar /> -->
      <div class="d-none md:flex justify-end w-1/6 md:w-1/6 gap-4">
        <profile-drop-down />
        <NotificationDropDown/>

      </div>

    </nav>

</div>

</template>

<script>
import Bookmarks            from './components/Bookmarks.vue'
import I18n                 from './components/I18n.vue'
import SearchBar            from './components/SearchBar.vue'
import CartDropDown         from './components/CartDropDown.vue'
import NotificationDropDown from './components/NotificationDropDown.vue'
import ProfileDropDown      from './components/ProfileDropDown.vue'
import Logo                 from '../Logo.vue'
import SettingPage       from './components/SettingPage.vue'

export default {
  name: 'the-navbar-horizontal',
  props: {
    logo: { type: String                                                                                                          },
    navbarType: {
      type: String,
      required: true
    }
  },
  components: {
    Logo,
    Bookmarks,
    I18n,
    SearchBar,
    CartDropDown,
    NotificationDropDown,
    ProfileDropDown,
    SettingPage
  },
  computed: {

    navbarColor () {
      let color = '#fff'
      if (this.navbarType === 'sticky') color = '#f7f7f7'
      else if (this.navbarType === 'static') {
        if (this.scrollY < 50) {
          color = '#f7f7f7'
        }
      }

      if (this.isThemedark === 'dark') {
        if (color === '#fff') {
          color = '#10163a'
        } else {
          color = '#262c49'
        }
      }

      return color
    },
    isThemedark ()          { return this.$store.state.theme                                                                       },
    navbarStyle ()          { return this.navbarType === 'static' ? {transition: 'all .25s ease-in-out'} : {}                      },
    navbarClasses ()        { return this.scrollY > 5 && this.navbarType === 'static' ? null : 'd-theme-dark-light-bg shadow-none' },
    scrollY ()              { return this.$store.state.scrollY                                                                     },
    verticalNavMenuWidth () { return this.$store.state.verticalNavMenuWidth                                                        },
    windowWidth ()          { return this.$store.state.windowWidth                                                                 }
  }
}

</script>

