<template>
  <div class="w-full flex mt-2 md:mt-0 items-center justify-between md:justify-start ml-1 md:ml-0">

    <div class="flex justify-between align-items-center bg-h-primary-light rounded p-0 -mr-4 md:mr-3">
          <b-tooltip target="tooltip-target-2" triggers="hover">

            <span class="m-0 text-white text-xs dana-font text-light pl-2">{{ activeUserInfo.point_balance | money}} <strong class="text-xs mx-1">سکه</strong></span>

          </b-tooltip>

          <b-button id="tooltip-target-2"  class=" px-4 rounded-lg py-3 mr-3 bg-h-primary-light   hover:bg-h-primary-light border-0  ">
            {{ Intl.NumberFormat('fa-IR', { maximumSignificantDigits: 3 }).format(activeUser.balance) }} تومان
          </b-button>


    </div>

     <div class="ml-0 text-base md:ml-0">
      <div class="d-none md:flex gap-3">
            <span  @click="activePrompt = true"  class="cursor-pointer  text-white router-link-exact-active router-link-active"> افزایش اعتبار</span>

        <router-link to="/apps/ticket" class="text-white">
          پشتیبانی
        </router-link>

        <router-link to="/apps/paymentManage" class="text-white">
          مالی
        </router-link>

      </div>
      <div class=" md:hidden">
       <!-- icon -->
       <b-dropdown :text="activeUserInfo.name+' عزیز'" block variant="primary" class="m-2" right>
    <b-dropdown-item href="#">
      <span
        class="flex py-2 cursor-pointer user user-drop text-primary"
        @click="$router.push('/pages/user-settings')">
        <span class="">
          <feather-icon icon="SettingsIcon" svgClasses="w-4 h-4" /> تنظیمات کاربری
        </span>
      </span>
    </b-dropdown-item>
    <b-dropdown-item href="#">
      <span
        class="flex py-2 cursor-pointer user user-drop text-primary"
        @click="$router.push('/apps/addKey')">
        <span class="">
          <feather-icon icon="LockIcon" svgClasses="w-4 h-4" /> افزودن کلید SSH
        </span>
      </span>

    </b-dropdown-item>
    <b-dropdown-item href="#">
      <span
        class="flex py-2 cursor-pointer user user-drop text-primary"
        @click="$router.push('/apps/ticket')">
        <span class="">
          <feather-icon icon="user" svgClasses="w-4 h-4" /> پشتیبانی
        </span>
      </span>

    </b-dropdown-item>
    <b-dropdown-item href="#">
      <span
        class="flex py-2 cursor-pointer user user-drop text-primary"
        @click="$router.push('/apps/paymentManage')">
        <span class="">
          <feather-icon icon="Person" svgClasses="w-4 h-4" /> مالی
        </span>
      </span>

    </b-dropdown-item>
    <b-dropdown-item>
      <feather-icon icon="DollarIcon" svgClasses="w-4 h-4" />

      <span  @click="activePrompt = true"  class="cursor-pointer router-link-exact-active router-link-active"> افزایش اعتبار</span>
    </b-dropdown-item>

    <b-dropdown-item href="#">
     <span
        class="flex py-2 cursor-pointer user user-drop text-primary"
        @click="logout">
        <span class="">
          <feather-icon icon="LogOutIcon" svgClasses="w-4 h-4" /> خروج
        </span>
      </span>
    </b-dropdown-item>
  </b-dropdown>


    </div>

      </div>

    <!--  prompt area-->
    <vs-prompt
      class="w-full"
      color="#0092e1"
      title="افزایش شارژ"
      @accept="acceptAlert"
      accept-text="پرداخت"
      cancel-text="انصراف"
      :active.sync="activePrompt">
      <div class="con-exemple-prompt">
        <vs-input-number color="#0092e1" min="80000"  v-model="amountCharge"  step="10000"/>
        <p class="text-center">تومان</p>
        <vs-alert :active="true" color="#0092e1" icon="new_releases" >
          <span class="text-white"> مبلغ قابل پرداخت با احتساب 10 درصد مالیات ارزش افزوده <b class="text-success">{{realPrice | money}}</b> تومان</span>
        </vs-alert>
        <!-- <vs-alert v-if="amountCharge >= 1000000" :active="true" color="#28C76E" icon="new_releases" class="mt-4">
          <span class="text-white"> هوراا! مبلغ وارد شده شامل هدیه هایو می شود با شارژ این مبلغ <b class="text-success">{{giftedAmount | money}}</b> سکه هدیه دریافت کنید.</span>
        </vs-alert> -->

        <div style="margin-top: 30px">
          <vs-alert :active="true" color="#090c2b" icon="new_releases" style="height:max-content">
            <span class="text-h-warning">مهم</span>
            <span class="text-white text-justify"> قبل از شارژ از موجود بودن ظرفیت سرور ها از طریق صفحه ساخت سرور اطمینان حاصل کنید، عودت وجه درصورت شارژ اشتباه وجود ندارد.</span>
          </vs-alert>
          <vs-alert :active="true" color="#090c2b" icon="new_releases" style="height:max-content">
            <span class="text-h-warning">توجه</span>
            <span class="text-white text-justify"> حداقل شارژ 80 هزار تومان و حداقل مبلغ برای ساخت سرور 20 هزار تومان است.</span>
          </vs-alert>
        </div>
        <div class="mt-5 mb-2 flex w-full items-center justify-between">
          <div class="flex flex-col">
            <b-button size="small" class="bg-h-success text-sm cursor-pointer" type="border" @click="encteCode = !encteCode">
              کد هدیه دارید ؟
            </b-button>
<!--            <div v-if="!encteCode" class="mb-2">-->
<!--              <router-link class="mt-2 text-sm position-absolute" :to="{path: '/pages/user-settings', query: { salescooperation : 'show' }}">-->
<!--                <p class="text-danger" @click="activePrompt = false">  دریافت کد هدیه</p>-->
<!--              </router-link>-->
<!--            </div>-->
          </div>
          <div v-if="encteCode" class="flex w-7/12  justify-center items-center">
            <vs-input size="small" class="ml-2 w-7Z/12" v-model="offCode" />
            <vs-button @click="confirmOffCode" size="small" color="primary">اعمال</vs-button>
          </div>
        </div>
        <div v-if="offValueCalculate" class="mt-5 flex items-center justify-center ">
          <span class="text-sm font-medium m-2">
            افزایش اعتبار هایو بعد از پرداخت:
          </span>
          <vs-chip class="dana-font shadow-md m-2 animate-bounce" transparent color="success">
            <vs-avatar color="#cf2f60" text="هایو"/>
            <span class="dana-font text-black ">
              {{ offValueCalculate }}
            </span>
          </vs-chip>
        </div>

        <br />
      </div>
    </vs-prompt>
    <!--end  prompt area-->


  </div>

</template>

<script>
import useJwt from '@/http/authConfig'
import CountTo from 'vue-count-to'
export default {
  components: { CountTo},
  data () {
    return {
      startVal: 1,
      verifyoffCode:'',
      amountCharge : 80000,
      offPercent:'',
      offValueCalculate: '',
      activePrompt : false,
      encteCode: false,
      offCode:'',
      settings: { // settings
        maxScrollbarLength: 60,
        wheelSpeed: 60
      }
    }
  },
  computed: {
    activeUserInfo () {
      return this.$store.state.AppActiveUser
    },
    realPrice () {
      return ((this.amountCharge * 1) + (0.10 * this.amountCharge))
    },
    giftedAmount () {
      if (this.amountCharge >= 1000000 && this.amountCharge <= 1999999) {
        return (this.amountCharge * 10 / 100)
      }
      if (this.amountCharge > 1999999 && this.amountCharge <= 2999999) {
        return (this.amountCharge * 15 / 100)
      }
      if (this.amountCharge > 2999999 && this.amountCharge <= 3999999) {
        return (this.amountCharge * 20 / 100)
      }
      if (this.amountCharge > 3999999 && this.amountCharge <= 5999999) {
        return (this.amountCharge * 25 / 100)
      }
      if (this.amountCharge > 5999999 && this.amountCharge <= 9999999) {
        return (this.amountCharge * 30 / 100)
      }
      if (this.amountCharge > 9999999 && this.amountCharge <= 19999999) {
        return (this.amountCharge * 35 / 100)
      }
      if (this.amountCharge > 19999999 && this.amountCharge <= 29999999) {
        return (this.amountCharge * 40 / 100)
      }
      if (this.amountCharge >= 30000000) {
        return (this.amountCharge * 50 / 100)
      }
    },
    scrollbarTag () {
      return this.$store.getters.scrollbarTag
    },
    activeUser () {
      return this.$store.state.AppActiveUser
    },
    windowWidth () {
      return this.$store.state.windowWidth
    },
    windowWidthBol () {
      return this.$store.state.windowWidth < 1198
    }
  },
  methods: {
    logout () {
      // this.$router.push('/pages/login').catch(() => {})
      this.$http('user/logout').then((res) => {
        localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
        localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
        localStorage.removeItem('userInfo')
        localStorage.removeItem('dis')

        this.$vs.notify({
          title: 'خروج',
          text: res.data.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'primary'
        })

        // locationbar.removeItem('isauth')

        this.$store.commit('auth/USER_LOGIN_CONTROL', {value :false})
        // go to login

        this.$router.push('/')

      }).catch((err) => {
        window.location.href = '/'
        // this.$router.push('/pages/login').catch(() => {})
        localStorage.clear()
        this.$vs.notify({
          title: 'Error',
          text: err.response.data.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      })

    },

    beforeDestroy () {
    // location.reload()
    },
    headerToIncomePage () {
      window.open('https://www.haio.ir/earn-money-from-writing-at-home/', '_blank')
    },
    acceptAlert () {
      // const authtoken = localStorage.getItem('accessToken')
      this.$http.post('https://api.haio.ir/v1/invoice/charge/add', {
        amount: this.amountCharge,
        off_code: this.verifyoffCode,
        payment_side:'2',
        redirect_url: 'https://console.haio.ir/pages/payment'
      })
        .then(async (res) => {
          window.location = (res.data.params.payment_url)
          // console.log("Set follow txt record at your dns server:")
          // console.log(res.data.params)
        })
        .catch(error => {
          this.$vs.notify({
            color:'danger',
            title:' شارژ حساب',
            text:error.response.data.message
          })

        })
    },
    confirmOffCode () {
      this.$vs.loading()
      this.$http.post(`/sale/cooperation/code/validation?off_code=${this.offCode}`).then((res) => {
        // console.log('verfy', res)
        if (res.data.code === 200) {
          this.$vs.loading.close()
          this.offPercent = res.data.params.off
          this.verifyoffCode = this.offCode
          this.offValueCalculate = this.amountCharge * this.offPercent / 100
          this.$vs.notify({text: 'کد تخفیف اعمال شد ', color: '#24c1a0'})
        }
      }).catch((err) => {
        this.$vs.loading.close()
        this.$vs.notify({text: err.response.data.message})
      })
    }
  }
}


</script>

<style >
a.dropdown-item:hover {
  background: none !important;
}
.overlay-payment {
  position: absolute;
  right: 27rem;
  height: 15px;
  border-radius: 25px;
  background: #0092e1;
  width: 0;
  transition: .5s ease;
  opacity:0;
  color: white;
  font-size: 20px;
  padding: 20px;
  text-align: center;
}
.parent-area:hover .overlay-payment {
  position: static;
  opacity: 1;
  width: 135px;
  text-align: center;
}

.parent-area:hover .selected-icon {
  transition: .5s ease;
  color: white !important;
  background-color: #3f2553 !important;
}
.moneyBackground {
  background-color: #64d771 !important;
}

.coin-background {
  background-color: #ffc200 !important;
}
.res-dropdownitem {
  position:absolute !important;
  left: 55% !important;
}
.fix-align {
  left: 55%;
}
[dir] ul.dropdown-menu.dropdown-menu-right.show{
background: white !important;
}
.dropdown-toggle::after {
  display: none !important;
}
</style>
